// This file can be replaced during build by using the `fileReplacements` array.
export const environment = {
    production: false,
    stripe_token: 'STRIPE_TOKEN',
    paypal_token: 'PAYPAL_TOKEN',
    loadGif: 'assets/images/loader.gif',

    loading: {
        spinner: 'circles',
        duration: 3000
    },

    // PARAMETROS NEW ROLLING PRO
    firebase: {
        apiKey: 'AIzaSyBgmFSjwQsnXaWREGx6wKIJ23c5_qhCeqI',
        authDomain: 'newrolling-pro.firebaseapp.com',
        projectId: 'newrolling-pro',
        storageBucket: 'newrolling-pro.appspot.com',
        messagingSenderId: '883404852630',
        appId: '1:883404852630:web:c9496b6151d0689e0861b7',
        measurementId: 'G-8E9QPRPXFE'
    },
    bannerDefault: 'https://firebasestorage.googleapis.com/v0/b/newrolling-pro.appspot.com/o/assets%2Fimages%2Fbanner-default.jpg?alt=media&token=370f7870-1d05-4259-8a22-064e33789d08',
    urlBase: 'https://us-central1-newrolling-pro.cloudfunctions.net',
    urlBaseWompy: 'https://us-central1-newrolling-pro.cloudfunctions.net/Wompi',
    urlBaseNR: 'https://api.newrolling.com',
    apikey: '97ccc90e8fe-cce525a10ddf3-4cf7-415a29cd',

    // CREDENCIALES DE PRO New Rolling
    wompi: {
        grant_type: 'client_credentials', // Dejar siempre client_credentials.
        audience: 'wompi_api', // Dejar siempre "wompi_api"
        client_id: 'aa4c30e1-0757-4d03-a20f-b9cc2663bf5c', // El client id del aplicativo de wompi bajo el cual desea ejecutar la acción en el api
        client_secret: '259c97c1-06fe-4137-ab29-b331b92f0fcb', // Es la llave secreta del aplicativo de wompi bajo el cual desea ejecutar la acción en el api
    },

    // TWILIO CREDENCIALES new rolling
    twilio: {
        TWILIO_SERVICE_ENDPOINT: 'https://verify.twilio.com/v2/Services/VA037cd4a1d0556aceea8d4d9524af6c24/Verifications',
        TWILIO_SERVICE_VERIFICATION_CHECK: `https://verify.twilio.com/v2/Services/VA037cd4a1d0556aceea8d4d9524af6c24/VerificationCheck`,
        TWILIO_AUTH_TOKEN: 'eae5d7503587447a21af4311385d376c',
        TWILIO_ACCOUNT_SID: 'ACe4c513b1b576a81a7304c06951362f8c',
        TWILIO_SERVICE_TOKEN: 'VA037cd4a1d0556aceea8d4d9524af6c24',
        TWILIO_MESSAGING_SERVICE_SID: 'MGdb0d97a058ccfdc75431f7c1aa3a553d'
    },

    countCharacter(string) {
        let limit = (this.width < 1560) ? 25 : 45;
        if (string.length > limit) {
            string = string.substring(0, limit) + '...';
        }
        return string;
    },

    breadcrumbDynamic(arrayBreadcrumb) {
        let sendBreadcrumbDynamic = [];
        arrayBreadcrumb.forEach(element => {
            if (element != '') {
                let info = {
                    'name': element.replaceAll('-', ' '),
                    'url': element,
                };
                sendBreadcrumbDynamic.push(info);
            }
        });
        return sendBreadcrumbDynamic;
    }
}
    ;

